'use client';

import {useEffectOnce} from 'react-use';
import {Logo} from '@job-ish/blocks/components';
import {Anchor, Card, Paper} from '@job-ish/ui/components';
import {useToast} from '@job-ish/ui/hooks';
import {useSearchParams} from 'next/navigation';

import {SSOLogin} from './sso-login';

export const LoginBlock = () => {
	const searchParams = useSearchParams();
	const loggedOut = Boolean(searchParams.get('logged-out'));

	const {show: showLoggedOutToast} = useToast({
		accent: 'info',
		accentPosition: 'left',
		description: 'You have been successfully logged out.',
		duration: 1500,
		title: 'Logged Out Successfully',
	});

	useEffectOnce(() => {
		if (loggedOut) {
			showLoggedOutToast();
		}
	});

	return (
		<div className="z-10 flex min-w-full grow flex-col">
			<Card
				bordered
				className="relative mx-auto my-0 flex min-w-full max-w-lg grow flex-col justify-center gap-0 rounded-xl sm:my-auto sm:min-w-[468px] sm:grow-0"
				padding="none"
			>
				<div className="absolute left-0 top-0 h-full w-full rounded-lg bg-gradient-to-br from-plum11 via-blue11 to-plum11 opacity-20 blur-3xl" />
				<Paper
					className="z-10 mt-0 flex h-full w-full grow flex-col justify-center gap-6 sm:grow-0 sm:rounded-xl"
					padding="lg"
				>
					<Card.Header className="w-full flex-col text-center">
						<div className="mx-auto mb-6 h-12 w-fit">
							<a href="https://job-ish.com" rel="noopener noreferrer">
								<Logo height={75} width={125} />
							</a>
						</div>
						<div className="flex flex-col gap-1">
							<Card.Title className="text-xl font-bold text-mauve12">Sign in to job-ish</Card.Title>
							<Card.Description className="text-sm text-mauve11">
								Get hired faster and accelerate your career.
							</Card.Description>
						</div>
					</Card.Header>
					<Card.Body className="w-full">
						<SSOLogin />
					</Card.Body>
					<Card.Footer className="block text-xs">
						Don&apos;t have an account? An account will automatically be created for you on sign in. By
						signing in, you agree to our{' '}
						<Anchor
							className="inline"
							href="https://job-ish.com/terms"
							rel="noopener noreferrer"
							target="_blank"
						>
							Terms of Service
						</Anchor>{' '}
						and{' '}
						<Anchor
							className="inline"
							href="https://job-ish.com/privacy-policy"
							rel="noopener noreferrer"
							target="_blank"
						>
							Privacy Policy
						</Anchor>
						.
					</Card.Footer>
				</Paper>
			</Card>
		</div>
	);
};
