'use client';
import {useState} from 'react';
import {useCookie, useEffectOnce} from 'react-use';
import {roboto} from '~/assets/fonts/roboto';
import GoogleIcon from '~/assets/images/google-icon.png';
import {REDIRECTED_FROM} from '~/middleware/auth';
import {REDIRECT_SEARCH_PARAMS_COOKIE} from '~/shared/constants/cookies';
import {useSupabase} from '~/shared/hooks/use-supabase';
import {TextButton} from '@job-ish/ui/components';
import {useToast} from '@job-ish/ui/hooks';
import clsx from 'clsx';
import omit from 'lodash.omit';
import {usePathname, useRouter, useSearchParams} from 'next/navigation';

import type {Provider} from '@supabase/supabase-js';
import type {Route} from 'next';

export const SSOLogin = () => {
	const {supabase} = useSupabase();
	const searchParams = useSearchParams();
	const router = useRouter();
	const pathname = usePathname();
	const [authLoading, setAuthLoading] = useState<false | Provider>(false);
	const authError = [...searchParams.values()].some(value => value.includes('error'));
	const [, setRedirectSearchParamsCookie] = useCookie(REDIRECT_SEARCH_PARAMS_COOKIE);

	const redirectedFrom = searchParams.get(REDIRECTED_FROM);

	const {show: showErrorToast} = useToast({
		accent: 'danger',
		accentPosition: 'left',
		duration: 2500,
		description: "We've encountered an error while trying to authenticate you. Please try again.",
		title: 'Authentication Failed',
	});

	const attemptSupabaseOAuthRequest = async ({provider}: {provider: Provider}) => {
		setAuthLoading(provider);
		setRedirectSearchParamsCookie(
			JSON.stringify(
				omit(Object.fromEntries(new URLSearchParams(document.location.search)), [REDIRECTED_FROM]),
			),
			{sameSite: 'lax'},
		);
		await supabase.auth.signInWithOAuth({
			provider,
			options: {
				redirectTo:
					redirectedFrom === null
						? `${location.origin}/api/callback`
						: `${location.origin}/api/callback?redirect-to=${redirectedFrom}`,
			},
		});
		setAuthLoading(false);
	};

	useEffectOnce(() => {
		if (authError) {
			showErrorToast();
			router.replace(`${pathname}?${REDIRECTED_FROM}=${redirectedFrom}` as Route);
		}
	});

	return (
		<TextButton
			className={clsx(
				roboto.className,
				'h-10 w-full rounded-[4px] border px-3 py-0 text-sm font-medium tracking-wide transition-all [&&]:border-[#747775] [&&]:bg-[#ffffff] [&&]:text-[#1f1f1f] [&>div]:gap-4',
			)}
			color="neutral"
			iconLeft={() => <img alt="Google Logo" className="h-5 w-5" src={GoogleIcon.src} />}
			loading={authLoading === 'google'}
			onPress={() => attemptSupabaseOAuthRequest({provider: 'google'})}
		>
			Sign in with Google
		</TextButton>
	);
};
